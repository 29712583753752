.logger {
  position: absolute;
  bottom: 20px;
  right: 20px;

  color: #333;
  background: white;
  display: none;
}
.pan-indicator {
  bottom: 0;
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100px;

  .outer {
    top: 0;
    left: 0;
    z-index: 10;
    position: relative;
    width: 100px;
    height: 100px;
    _border: 1px solid red;
    opacity: 0.7;
    margin: 0 auto;
  }
  .inner {
    top: -100px;
    left: 0;
    z-index: 20;
    position: relative;
    width: 100px;
    height: 100px;
    _border: 1px solid yellow;
    margin: 0 auto;
  }
}
