@font-face {
  font-family: 'abel_regular';
  src: url('./fonts/abel-regular-webfont.woff2') format('woff2'),
    url('./fonts/abel-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  background-color: #000;
}

#app,
.pswp {
  font-family: 'abel_regular', 'Helvetica', sans-serif;
}
#app {
  // add all styles that should apply to the whole project within this
  color: #555;
  font-size: 1.2em;
}
.nav {
  font-family: 'abel_regular', 'Helvetica', sans-serif;
  height: 2em;
  width: 100%;
  font-size: 1em;
  background: #fff;
  z-index: 2;
  position: absolute;
  padding: 0.2em;
  text-align: center;
  opacity: 0.7;
}

.nav span {
  cursor: pointer;
}

.map {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
}

.mapboxgl-control-container {
  position: absolute;
  top: 5em;
  right: 0.4em;
}

.mapboxgl-ctrl-bottom-right {
  display: none;
}

// POPUP / TOOLTIP

.mapboxgl-popup-content {
  max-width: 40vw;
  color: #555;
  padding: 0.6em;
}
.mapboxgl-popup-content h3 {
  font-size: 1.4em;
  font-weight: normal;
  padding: 0;
  margin: 0;
  font-family: 'abel_regular', 'Helvetica', sans-serif;
  color: #b89300;
}
.mapboxgl-popup-content p {
  font-size: 1.1em;
}
@media screen and (max-width: 650px) {
  .mapboxgl-popup-content {
    max-width: 65vw;
  }
}
.mapboxgl-popup-close-button {
  display: none;
}

// change marker image, but NOT for the USER LOCATION MARKER
.mapboxgl-marker:not(.mapboxgl-user-location-dot) {
  background-image: url('./images/pin.png');
  width: 40px;
  height: 40px;
  background-size: 40px;
}

.mapboxgl-user-location-dot {
  background-color: #1da1f2;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
}

.mapboxgl-marker svg {
  display: none;
}
.mapboxgl-popup-content {
  font-family: 'abel_regular', 'Helvetica', sans-serif;
}

/* lightbox/photoswipe */
.pswp__caption__center {
  font-size: 1.1em;
  line-height: 1em;
  max-width: 80%;
}
.pswp__caption__center p {
  line-height: 1em;
  margin-bottom: 0;
}
.pswp__caption {
  a {
    text-decoration: underline;
    color: #dfc55c;
  }
  a:hover {
    text-decoration: none;
  }
}

#initial-loader {
  position: absolute;
  z-index: 9999999;
  opacity: 0.7;
  width: 100%;
  height: 100%;
  .active.dimmer {
    background-color: #000;
  }
  .ui.segment {
    width: 100%;
    height: 100%;
    border: none;
  }
  font-family: 'abel_regular', 'Helvetica', sans-serif;
  .ui.text.loader {
    font-size: 1.2em;
    margin-top: -1.5em;
    @media screen and (max-width: 650px) {
      margin-top: -2em;
    }
  }
  img.logo {
    max-width: 350px;
    margin-top: 7.5em;
    @media screen and (max-width: 650px) {
      margin-top: 5em;
      max-width: 80vw;
    }
  }
}

.layer-legend {
  font-family: 'abel_regular', 'Helvetica', sans-serif;
  font-size: 1.2em;
  padding: 0.4em 0.6em;
  background-color: #fff;
  z-index: 2;
  position: absolute;
  bottom: 10px;
  left: 10px;
  div:first-child {
    margin-right: 1em;
  }
  .item {
    display: inline-block;
    .indicator {
      width: 1em;
      height: 1em;
      display: inline-block;
      top: 0.1em;
      position: relative;
      margin-right: 0.3em;
    }
    .indicator.black {
      background-color: #000;
    }
    .indicator.grey {
      background-color: #6a6a6a;
    }
  }
}

canvas {
  outline: none;
}
