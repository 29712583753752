.info-dock {
  font-family: 'abel_regular', 'Helvetica', sans-serif;
  color: #555;

  .dock {
    @media screen and (max-width: 650px) {
      height: 101%;
    }
  }

  .top-strip {
    height: 5px;
    width: 100%;
    background-color: #fff;
    position: absolute;
    top: 0;
    z-index: 999;
  }
  .icon.close {
    position: absolute;
    padding: 20px;
    width: 40px;
    height: 40px;
    right: 0;
    margin-right: 0;
    color: #fff;
    text-shadow: 0 0 0.1em #000;
    font-size: 1.2em;
    opacity: 0.7;
    @media screen and (max-width: 650px) {
      color: #555;
      text-shadow: none;
      font-size: 1em;
    }
    cursor: pointer;
  }
  .icon.close:before {
    position: absolute;
    top: 33%;
    left: 37%;
    @media screen and (max-width: 650px) {
      top: 37%;
      left: 40%;
    }
  }
  .content-wrapper .head-image {
    cursor: zoom-in;
  }
  .content-wrapper .caption {
    padding: 0 0.4em;
    font-size: 1.05em;
    a {
      text-decoration: underline;
      color: #b89300;
    }
    a:hover {
      text-decoration: none;
    }
  }
  .head-image {
    @media screen and (max-width: 650px) {
      margin-top: 0.5em;
    }
  }
  .head-thumbs {
    margin-top: 0.5em;
    @media screen and (max-width: 650px) {
      margin-bottom: 1.2em;
    }
  }
  .head-thumb {
    height: 50px;
    width: auto;
    cursor: pointer;
    margin: 0 2px;
  }
  .head-thumb:hover {
    opacity: 0.8;
  }
  .description-content {
    padding: 1em;
  }
  .description-content h3 {
    font-size: 1.7em;
    font-weight: normal;
    padding: 0;
    margin: 0.5em 0 0 0;
    @media screen and (max-width: 650px) {
      margin-top: 0.3em;
    }
    font-family: 'abel_regular', 'Helvetica', sans-serif;
    color: #b89300;
  }
  .description-content .dates {
    font-size: 1.05em;
    margin: 0 0 0.7em;
  }
  .description-content .text-content {
    margin-top: 1.2em;
  }
  .description-content .caption .text-content {
    margin-top: 0.4em;
  }
  .description-content h4 {
    font-size: 1.1em;
    font-weight: 800;
    font-family: 'abel_regular', 'Helvetica', sans-serif;
  }
  .description-content p {
    font-family: 'abel_regular', 'Helvetica', sans-serif;
    font-size: 1.15em;
    line-height: 1.4em;
    a {
      text-decoration: underline;
      color: #b89300;
    }
    a:hover {
      text-decoration: none;
    }
  }
  figure {
    margin: 1em 0;
    max-width: 100%;
    height: auto;
    img {
      max-width: 100%;
      height: auto;
    }
  }

  /* PAGE CONTENT */
  img.alignleft {
    float: left;
    margin-right: 0.7em;
  }
  img.alignnone {
    width: 100%;
    height: auto;
  }
  ul li {
    font-size: 1.15em;
    line-height: 1.5em;
  }
}
