.semantic-search {
  .ui.search {
    margin: 0.6em 0.4em;
    text-align: center;
    @media screen and (max-width: 800px) {
      padding: 0 0.4em;
      min-width: 77vw;
    }
  }
  .ui.search .input {
    width: 100%;
  }
  .ui.search input,
  .ui.search .icon {
    font-size: 0.9em;
    @media screen and (max-width: 800px) {
      font-size: 0.95em;
    }
  }

  .ui.category.search > .results {
    .category > .name,
    .result .title .icon span {
      font-family: 'abel_regular', 'Helvetica', sans-serif;
    }
    .result .title .icon {
      display: inline;
    }
    .result .title .icon span {
      font-size: 1.1em;
      margin-left: 0.6em;
    }
    .icon.map {
      position: relative;
    }
  }
}
