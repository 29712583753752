.image-results {
  .item img.thumb {
    max-height: 5em;
    display: inline-block;
    margin-right: 1em;
  }
  .item .content {
    display: inline-block;
    vertical-align: top;
    padding-top: 0.5em;
    .show-full {
      margin-bottom: 1em;
    }
  }
  .hint {
    font-size: 0.9em;
    text-transform: uppercase;
    margin: 0.2em 0 0.6em;
  }
  .ui.dropdown {
    margin-bottom: 0.3em;
    width: 49%;
    min-width: 10px !important;
  }
  .ui.dropdown:nth-child(2n) {
    margin-left: 1%;
  }

  p.hint {
    margin-top: 1em;
    font-size: 1.2em;
  }

  .my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -1em; /* gutter size offset */
    width: auto;
  }
  .my-masonry-grid_column {
    padding-left: 1em; /* gutter size */
    background-clip: padding-box;
  }

  /* Style your items */
  .my-masonry-grid_column > div {
    /* change div to reference your elements you put in <Masonry> */
    margin-bottom: 1em;
  }

  .my-masonry-grid {
    .image,
    .content,
    .show-onmap {
      cursor: pointer;
    }
    .content:hover,
    .show-onmap:hover {
      color: #b89300;
    }
    @media only screen and (max-width: 600px) {
      .ui.card > .content > .header:not(.ui),
      .ui.cards > .card > .content > .header:not(.ui) {
        font-size: 1.1em;
      }
    }
  }
}
