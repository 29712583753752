.top-menu {
  position: fixed;
  z-index: 997;
  width: 100%;
  @media screen and (max-width: 800px) {
    top: 0.7em;
  }

  .ui.horizontal.menu {
    float: right;
    @media screen and (max-width: 800px) {
      float: none;
      margin: 0 auto;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
    }
    font-family: 'abel_regular', 'Helvetica', sans-serif;
    .ui.dropdown.item .icon:first-child {
      margin-right: 0.4em;
    }
    .category {
      text-transform: uppercase;
      color: #000;
    }
    .sub-category {
      padding-left: 0.5em;
    }
  }

  // dont make active item bold
  .ui.menu .ui.dropdown .menu > .active.item {
    font-weight: 100 !important;
  }
}

// icon on the left for vertical menu items
.ui.vertical.menu a.item > i.icon {
  float: left !important;
  margin: 0 0.5em 0 0 !important;
}

.layers-menu {
  font-family: 'abel_regular', 'Helvetica', sans-serif;
  position: fixed;
  top: 5em;
  right: 4.5em;
  z-index: 996;
  background-color: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;

  .ui.vertical.menu {
    font-family: 'abel_regular', 'Helvetica', sans-serif;
    _width: 20em;
    background-color: #fff;
  }

  .ui.form {
    padding: 1em;
    width: 100%;
  }
  .slider-label {
    text-transform: uppercase;
    font-size: 0.85em;
    padding: 0 0 0.8em 0;
  }
  .slider-wrapper {
    padding: 0.15em 0.7em 2em 0.7em;
  }
}

// slider overwrites
.noUi-horizontal {
  height: 6px;
  box-shadow: none;
  border: 0;
  background: #eee;
}

.noUi-horizontal .noUi-handle {
  height: 20px;
  width: 20px;
}
html:not([dir='rtl']) .noUi-horizontal .noUi-handle {
  right: -10px;
}

.noUi-handle {
  border-radius: 100%;
  background: #ffffff linear-gradient(transparent, rgba(0, 0, 0, 0.05));
  box-shadow: none;
}

.noUi-touch-area {
  background: transparent;
}

.noUi-handle:before,
.noUi-handle:after {
  content: none;
}
.noUi-handle,
input:focus,
textarea {
  outline: none !important;
}
// end slider
