.pan-viewer {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9998;
  background-color: #191a1a;
  color: #fff;
  .pan-wrapper {
    overflow: hidden;
    height: 100vh;
    img {
      height: 100%;
      width: auto;
    }
  }
  .toggle-wrapper {
    width: 100%;
    position: absolute;
    z-index: 9999;
    bottom: 20px;
    right: 0;
    text-align: center;
    .view-toggle {
      margin: 0 auto;
      background: transparent;
      color: #fff;
      cursor: pointer;
    }
  }
  .drag-hint {
    opacity: 1;
    transition: opacity 0.25s ease-in-out;
    -moz-transition: opacity 0.25s ease-in-out;
    -webkit-transition: opacity 0.25s ease-in-out;
    position: absolute;
    top: 45%;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    pointer-events: none;
    img {
      pointer-events: none;
    }
  }
  .drag-hint.hidden {
    opacity: 0;
  }
  .closer {
    padding: 20px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    text-shadow: 0 1px 2px #000;
  }
  .ui.positive.button,
  .ui.positive.buttons .button {
    background-color: #b89300;
  }

  .ui.positive.button:active,
  .ui.positive.buttons .button:active {
    background-color: #a98701;
    color: #fff;
    text-shadow: none;
  }

  .ui.positive.button:focus,
  .ui.positive.buttons .button:focus {
    background-color: #b89300;
    color: #fff;
    text-shadow: none;
  }

  .ui.positive.button:hover,
  .ui.positive.buttons .button:hover {
    background-color: #c8a000;
    color: #fff;
    text-shadow: none;
  }
}
