.video-scroller {
  width: 100%;
  height: 200%; // this value gets overwritten by state.scrollDistance
  position: absolute;
  z-index: 9998;
  background-color: #191a1a;
  color: #fff;
  video {
    position: fixed;
    height: 100%;
    object-fit: contain;
    @media (orientation: portrait) {
      height: 100%;
      object-fit: cover;
    }
  }
  .scroll-hint {
    padding-top: 7%;
    opacity: 1;
    transition: opacity 0.25s ease-in-out;
    -moz-transition: opacity 0.25s ease-in-out;
    -webkit-transition: opacity 0.25s ease-in-out;
    position: fixed;
    z-index: 99999;
    top: 50%;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    pointer-events: none;
    img {
      pointer-events: none;
    }
    .icon {
      font-size: 8em;
      opacity: 0.8;
      transform: translatey(0px);
      animation: float 4s ease-in-out infinite;
    }
  }
  .scroll-hint.hidden {
    opacity: 0;
  }
  .closer {
    padding: 20px;
    position: fixed;
    top: 0;
    right: 0;
    cursor: pointer;
    text-shadow: 0 1px 2px #000;
  }
  .ui.inline.loader {
    position: fixed;
    z-index: 99999;
    width: 100%;
    top: 42%;
    padding-top: 3em;
    background-color: transparent;
    font-family: 'abel_regular', 'Helvetica', sans-serif;
    font-size: 1.2em !important;
  }
}

/* ANIMATION */

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(3vh);
  }
  100% {
    transform: translatey(0px);
  }
}
