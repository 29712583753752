.side-menu {
  .top-menu {
    position: relative;
    z-index: unset;
    width: unset;

    .ui.vertical.menu {
      float: none;
      width: 100%;
      box-shadow: none;
      border-radius: 0;
      border-left: 0;
      border-right: 0;
      font-family: 'abel_regular', 'Helvetica', sans-serif;
      .ui.dropdown.item .icon:first-child {
        margin-right: 0.4em;
      }
      .category {
        text-transform: uppercase;
        color: #000;
      }
      .sub-category {
        padding-left: 0.5em;
      }
    }

    // dont make active item bold
    .ui.menu .ui.dropdown .menu > .active.item {
      font-weight: 100 !important;
    }
  }

  /* HACK SEMANTIC DROPDOWNS TO MAKE IT ACCORDION STYLE */
  @media screen and (max-width: 800px) {
    .top-menu {
      .ui.vertical.menu .dropdown.item .menu {
        left: 0;
        top: 1em;
        position: relative;
        margin-bottom: 1em;
      }
      .ui.dropdown .scrolling.menu,
      .ui.scrolling.dropdown .menu {
        max-height: unset;
      }
    }
  }

  .layers-menu {
    font-family: 'abel_regular', 'Helvetica', sans-serif;
    position: relative;
    top: unset;
    right: unset;
    z-index: unset;
    background-color: #fff;
    border: none;
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 1em;

    .ui.vertical.menu {
      font-family: 'abel_regular', 'Helvetica', sans-serif;
      width: 100%;
      border: none;
      background-color: #fff;
    }

    .slider-label {
      text-transform: uppercase;
      font-size: 0.85em;
      padding: 0 0 0.8em 0;
    }
    .slider-wrapper {
      padding: 0.15em 0.7em 2em 0.7em;
    }
  }
}
