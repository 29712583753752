.image-search {
  .close.icon {
    position: relative;
    padding: 10px;
    width: 40px;
    height: 40px;
    color: #666;
    cursor: pointer;
  }
  .search-content {
    padding: 0.8em;
  }
}
